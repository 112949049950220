import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';
import inMemoryJWT from './inMemoryJWT';

const getXTotalCountHeaderValue = (headers) => {
    if (!headers.has('x-total-count')) {
        throw new Error(
            'The X-Total-Count header is missing in the HTTP Response.'
        );
    }
    return parseInt(headers.get('x-total-count'), 10);
};

const formatFilter = (filters) => {
    return Object.keys(filters).reduce((acc, filterKey) => {
        const [name, operator ='eq'] = filterKey.split(':');
        return {
            ...acc,
            [name]: `${filters[filterKey]}:${operator}`,
        };
    }, {});
};

function DataProvider(apiUrl) {
    const httpClient = (url, inOptions) => {
        const options = { ...inOptions };
        if (!options.headers) {
            options.headers = new Headers();
        }
        options.headers.set('Accept', 'application/json');
        const token = inMemoryJWT.getToken();

        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
            return fetchUtils.fetchJson(url, options);
        } else {
            // TODO - inMemoryJWT.setRefreshTokenEndpoint('/refresh-token');
            return inMemoryJWT.getRefreshedToken()
                .then((gotFreshToken) => {
                    if (gotFreshToken) {
                        options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
                    };
                    return fetchUtils.fetchJson(url, options);
                });
        }
    };

    const getList = (resource, params) => {
        // TODO - sort/paginate
        const { page: currentPage, perPage } = params.pagination;
        const { field, order } = params.sort;
        const filters = params.filter;
        const query = {
            sortBy: field,
            orderBy: order,
            currentPage,
            perPage,
            ...formatFilter(filters),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url)
            .then(({ headers, json }) => {
                return {
                    data: json,
                    total: getXTotalCountHeaderValue(headers),
                };
            });
    };

    const getOne = (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`)
            .then(({ json }) => ({ data: json }));
    };

    const getMany = (resource, params) => {
        // TODO - sort/paginate
        const filters = params.filter;
        const query = {
            sortBy: 'id',
            currentPage: 1,
            perPage: 100,
            ...formatFilter(filters),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url)
            .then(({ headers, json }) => {
                return {
                    data: json,
                    total: getXTotalCountHeaderValue(headers)
                };
            });
    };

    const getManyReference = (resource, params) => {
        // TODO - sort/paginate
        const filters = params.filter;
        const query = {
            sortBy: 'id',
            currentPage: 1,
            perPage: 100,
            ...formatFilter(filters),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url)
            .then(({ headers, json }) => {
                return {
                    data: json,
                    total: getXTotalCountHeaderValue(headers)
                };
            });
    };

    const fetch = (resource, params) => {
        let query = { ...params }; delete query.id;
        let url = `${apiUrl}/${resource}`;
        if (params.id) {
            url += `/${params.id}`;
        }
        if (query) {
            url += `?${stringify(query)}`;
        }
        return httpClient(url)
            .then(({ json }) => {
                console.log(json);
                return ({ data: json }); });
    };

    const update = (resource, params) => {
        let url = `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url, { method: 'PUT', body: JSON.stringify(params.data) })
            .then(({ json }) => { return({ data: json }); });
    };

    const updateMany = (resource, params) => {
        // TODO -- needs verification ?? right params.data or params[id].data?
        return Promise.all(params.ids.map((id) => {
            let url = `${apiUrl}/${resource}/${id}`;
            return httpClient(url, { method: 'PUT', body: JSON.stringify(params.data) });
        }))
            .then((responses) => {
                return ({ data: responses.map(({json}) => { return json.id; }) });
            });
    };

    const create = (resource, params) => {
        let url = `${apiUrl}/${resource}`;
        return httpClient(url, { method: 'POST', body: JSON.stringify(params.data) })
            .then(({json}) => {
                // TODO -- return original params or received json?
                let data = { ...params.data, id: json.id };
                return ({ data });
            });
    };

    const deleteF = (resource, params) => {
        let url = `${apiUrl}/${resource}`;
        return httpClient(url, { method: 'DELETE' })
            .then(({json}) => { return ({ data: json }); });
    };

    const deleteMany = (resource, params) => {
        return Promise.all(params.ids.map((id) => {
            let url = `${apiUrl}/${resource}/${id}`;
            return httpClient(url, { method: 'DELETE' });
        }))
            .then((responses) => {
                return ({ data: responses.map(({json}) => { return json.id; }) });
            });
    };

    return {
        getList,
        getOne,
        getMany,
        getManyReference,
        fetch,
        update,
        updateMany,
        create,
        delete: deleteF,
        deleteMany
    };
}

export default DataProvider;
