// in src/App.js
import * as React from "react";
import { Admin, Resource } from 'react-admin';
import { AuthProvider, DataProvider } from "./providers";
// import Dashboard from "./components/Dashboard";
import { Dashboard, ListTenant, EditTenant, ShowTenant } from "./components";

const myAuthProvider = AuthProvider('/auth');
const myDataProvider = DataProvider('/api');

const App = () => (
    <Admin dashboard={Dashboard} dataProvider={myDataProvider} authProvider={myAuthProvider} >
        <Resource name="tenants" list={ListTenant} edit={EditTenant} show={ShowTenant} />
    </Admin>
);

export default App;
