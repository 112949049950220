import React from 'react';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Card, Container } from '@material-ui/core';

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

export const NorthAmericaUsers = ({ tenantId }) => {
    const dataProvider = useDataProvider();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        let depth = 30;
        dataProvider.fetch(`tenants/${tenantId}/geolocation`, {depth})
            .then(({ data }) => {
                setResult(data);
                // setResult({data: [{lat: 41.1034, lon: -104.9059, radius: 6}]});
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, tenantId]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!result) return null;

    return (
        <Container maxWidth='md'>
            <Card>
                <ComposableMap projection="geoAlbers">
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                                geographies.map(geo => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill="#DDD"
                                        stroke="#FFF"
                                    />
                                ))
                        }
                    </Geographies>
                    {result.data.map(m => (
                        <Marker key={`${m.lat}${m.lon}`} coordinates={[m.lat, m.lon]}>
                                <circle r={m.radius} fill="#F53" />
                        </Marker>
                    ))}
                </ComposableMap>
            </Card>
        </Container>
    );
};

export const WorldUsers = ({ tenantId }) => {
    const dataProvider = useDataProvider();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.fetch(`tenants/${tenantId}/geolocation`, {})
            .then(({ data }) => {
                setResult(data);
                // setResult({data: [{lat: 41.1034, lon: -104.9059, radius: 6}]});
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, tenantId]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!result) return null;

    // projection="geoAlbers"
    return (
        <Container maxWidth='md'>
            <Card>
                <ComposableMap>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                                geographies.map(geo => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill="#DDD"
                                        stroke="#FFF"
                                    />
                                ))
                        }
                    </Geographies>
                    {result.data.map(m => (
                        <Marker key={`${m.lat}${m.lon}`} coordinates={[m.lat, m.lon]}>
                                <circle r={m.radius} fill="#F53" />
                        </Marker>
                    ))}
                </ComposableMap>
            </Card>
        </Container>
    );
};
