import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from 'react-admin';

// export const Dashboard = props => (
const Dashboard = () => (
  <Card>
    <Title title="Welcome to the Apporto Operations Dashboard" />
    <CardContent>
        This is the operations dashboard for Apporto.
    </CardContent>
  </Card>
);

export default Dashboard;
