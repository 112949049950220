import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';

const Histogram = ({ tenantId }) => {
    const dataProvider = useDataProvider();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        let depth = 30;
        let houroffset = new Date().getTimezoneOffset() / 60.0;
        dataProvider.fetch(`tenants/${tenantId}/histogram`, {depth, houroffset})
            .then(({ data }) => {
                setResult(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, tenantId]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!result) return null;

    return (
        <BarChart
            width={800} height={300} data={result.data}
            margin={{top: 5, right: 30, left: 20, bottom: 5, }} >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hour" />
            <YAxis />
            <Legend />
            <Bar dataKey="open" fill="#8884d8" />
            <Bar dataKey="close" fill="#82ca9d" />
        </BarChart>
    );
};

export default Histogram;
