import { fetchUtils } from 'ra-core';

const inMemoryJWTManager = () => {
    let httpClient = fetchUtils.fetchJson;
    let inMemoryJWT = null;
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshTimeOutId;
    let refreshEndpoint = '/refresh-token';

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // Countdown and refresh the JWT
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        );
    };

    const abortRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    };

    // call /refresh-token endpoint
    const getRefreshedToken = () => {
        return httpClient(refreshEndpoint, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json' }),
            credentials: 'include',
        })
            .then(response => {
                if (response.status !== 200) {
                    eraseToken();
                    global.console.log('Token renewal failure');
                    return { token: null };
                }
                return response.json;
            })
            .then(({ accessToken, tokenExpiry }) => {
                if (accessToken) {
                    setToken(accessToken, tokenExpiry);
                    return true;
                }
                eraseToken();
                return false;
            });
    };

    const getToken = () => inMemoryJWT;

    const setToken = (token, delay) => {
        inMemoryJWT = token;
        refreshToken(delay);
        return true;
    };

    const eraseToken = () => {
        inMemoryJWT = null;
        abortRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    };

    // disconnect listener
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    return {
        eraseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
    }
};

export default inMemoryJWTManager();
