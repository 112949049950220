import * as React from "react";
import { List, Datagrid, TextField, TopToolbar, EditButton, UrlField, EditGuesser, Show, SimpleShowLayout } from 'react-admin';
import Histogram from "./Histogram";
import { WorldUsers, NorthAmericaUsers } from "./Geolocation";
// import { Card, Container } from '@material-ui/core';

export const ListTenant = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="schema" />
            <UrlField source="website" />
        </Datagrid>
    </List>
);

const TenantTitle = ({record}) => {
    return <span>Tenant - {record ? `${record.name}` : `${record.id}`}</span>;
};

const TenantShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        {/* custom actions */}
    </TopToolbar>
);

export const ShowTenant = props => (
    <Show title={<TenantTitle />} actions={<TenantShowActions />} {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            {/* <TextField source="schema" /> */}
            {/* <UrlField source="website" /> */}
            <p>Session Histogram over last 30 days</p>
            <Histogram tenantId={props.id} />
            <p>Usage across regions over last 30 days</p>
            <NorthAmericaUsers tenantId={props.id} />
            <WorldUsers tenantId={props.id} />
        </SimpleShowLayout>
    </Show>
);

export const EditTenant = EditGuesser;
