import inMemoryJWT from './inMemoryJWT';
import { fetchUtils } from 'ra-core';
// import decodeJwt from 'jwt-decode';

function AuthProvider(authUrl) {
    let httpClient = fetchUtils.fetchJson;
    inMemoryJWT.setRefreshTokenEndpoint(authUrl + "/refresh-token");

    const login = ({username, password}) => {
        let url = authUrl + "/login";
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include'
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json;
            })
            .then(({accessToken, tokenExpiry}) => {
                return inMemoryJWT.setToken(accessToken, tokenExpiry);
            });
    };

    const logout = () => {
        let url = authUrl + "/logout";
        inMemoryJWT.eraseToken();
        return httpClient(url, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        }).then(() => '/login');
    };

    const checkAuth = () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
        });
    };

    const checkError = (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.eraseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    };

    const getPermissions = () => {
        return inMemoryJWT.waitForTokenRefresh()
            .then(() => {
                return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
            });
    };

    return {
        login,
        logout,
        checkAuth,
        checkError,
        getPermissions
    };
}

export default AuthProvider;
